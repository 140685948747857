import React from "react";
import styled from "styled-components";
import Finger from "@assets/svg/mid-finger.svg";
import TextCircle from "@assets/svg/text-circle.svg";

const CircleLayout = styled.div`
  position: relative;
  width: 8.75rem;
  height: 8.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 996px) {
    display: none;
  }
`

const FingerImg = styled(Finger)``

const TextCircleImg = styled(TextCircle)`
  position: absolute;
  top: 0;
  left: 0;
  animation: rotation 10s infinite linear;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

const Circle: React.VFC = () => {
  return <CircleLayout>
    <FingerImg/>
    <TextCircleImg/>
  </CircleLayout>;
}

export default Circle;
