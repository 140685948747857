import React from "react";
import styled, {css} from "styled-components";

interface ICategory {
  category: string;
  onClick: (category: string) => void;
  isSelected?: boolean;
}

interface ITag {
  isSelected: boolean;
}

const Tag = styled.div<ITag>`
  display: inline-block;
  padding: 0.7em 1.75em 0.5em;
  border: 1px solid #4f4f4f;
  border-radius: 1000em;
  line-height: 1em;
  cursor: pointer;
  user-select: none;
  background-color: rgba(1, 1, 1, 0.25);
  ${({isSelected}) => isSelected && css`
    background-color: rgba(255, 255, 255, 0.5);
    color: #111;
    cursor: inherit;
  `}
`

const CategoryTag: React.VFC<ICategory> = ({category, onClick, isSelected = false}) => {
  return <Tag isSelected={!!isSelected} onClick={() => onClick(category)}>#{category}</Tag>
}

export default CategoryTag;
