import React from 'react';

export const DataContext = React.createContext<IContext>({
  backgrounds: [],
  advices: [],
  categories: []
});

interface IDataContextWrapper {
  backgrounds: IImage[],
  advices: IAdvice[],
  categories: ICategory[],
  children: React.ReactNode
}

const DataContextWrapper: React.VFC<IDataContextWrapper> = ({children, backgrounds, advices, categories}) => {
  return <DataContext.Provider value={{backgrounds, advices, categories}}>
    {children}
  </DataContext.Provider>;
}

export default DataContextWrapper;
