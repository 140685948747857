import React from "react";
import {useTransition, config, animated} from "react-spring";
import Background from "@components/background";
import styled from "styled-components";

interface IBackgroundTrans {
  adviceList: IAdvice[];
}

const Layout = styled.div`
`

const Wrap = styled(animated.div)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  vertical-align: middle;

  & > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  & > .gatsby-image-wrapper > div {
    display: none !important;
  }

  & > .gatsby-image-wrapper > picture > img[data-placeholder-image] {
    display: none !important;
  }

  & > .gatsby-image-wrapper > picture > img[data-main-image] {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
    position: relative !important;
  }
`

const BackgroundTrans: React.VFC<IBackgroundTrans> = ({adviceList}) => {
  const transitions = useTransition(adviceList, {
    from: {transform: "scale(0, 0)"},
    enter: {transform: "scale(1, 1)"},
    leave: {transform: "scale(0, 0)"},
    delay: 300,
    config: config.default
  })

  return <Layout>
    {transitions((styles, currentAdvice) => (
      <Wrap style={styles} data-key={currentAdvice.background}>
        <Background code={currentAdvice.background}/>
      </Wrap>
    ))}
  </Layout>
}

export default BackgroundTrans;
