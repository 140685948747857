import React from "react"
import styled from "styled-components";
import Arrow from "@assets/svg/arrow.svg";

const FooterLayout = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 750px) {
    justify-content: center;
  }
  align-items: baseline;
  padding: 0 3.75rem 3.75rem 3.75rem;

  @media (max-width: 750px) {
    padding: 0 0.75rem 3.25rem 0.75rem;
  }
`

const CapsNav = styled.span`
  text-transform: uppercase;
  user-select: none;
`

const BackNav = styled.div`
  cursor: pointer;

  @media (max-width: 750px) {
    display: none;
  }
`

const ForwardNav = styled.div`
  cursor: pointer;
`

const ArrowBack = styled(Arrow)`
  margin-right: 1rem;
  width: 1.25em;
  height: 0.75em;
`

const ArrowForward = styled(Arrow)`
  transform: rotate(180deg);
  margin-left: 1rem;
  width: 1.25em;
  height: 0.75em;
`

interface ILayoutProps {
  onBack: () => void
  onForward: () => void
}

const Footer: React.VFC<ILayoutProps> = ({onBack, onForward}) => {
  return (
    <FooterLayout>
      <BackNav onClick={onBack}>
        <ArrowBack/>
        <CapsNav>To the past advice</CapsNav>
      </BackNav>
      <ForwardNav onClick={onForward}>
        <CapsNav>To the next advice</CapsNav>
        <ArrowForward/>
      </ForwardNav>
    </FooterLayout>
  )
}

export default Footer;
