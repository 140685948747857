import React, {useContext, useMemo} from "react";
import {getImage, withArtDirection} from "gatsby-plugin-image";
import {DataContext} from "@components/dataContext";
import SpikeImage from "@components/SpikeImage";

interface IBackgroundListViewModel {
  code: string;
}

const Background: React.VFC<IBackgroundListViewModel> = ({code}) => {
  const {backgrounds} = useContext(DataContext);

  const largeImageData = useMemo(() => backgrounds.find(
      (image) => image.name === code && image.relativeDirectory === 'background'),
    [code]);

  const smallImageData = useMemo(() => backgrounds.find(
      (image) => image.name === code && image.relativeDirectory === 'mobileBackground'),
    [code]);

  const largeImage = getImage(largeImageData as any);
  const smallImage = getImage(smallImageData as any);

  if (!largeImage) {
    return null;
  }

  if (!smallImage) {
    return null;
  }

  const image = withArtDirection(largeImage, [
    {
      media: "(max-width: 750px)",
      image: smallImage,
    },
  ])

  if (image) {
    return <SpikeImage image={image} alt={code}/>
  }

  return null;
}

export default Background;
