import React, {useContext} from "react";
import styled from "styled-components";
import {DataContext} from "@components/dataContext";
import {animated, config, useTransition} from "react-spring";

interface ICategory {
  onSet: (category: string) => void
  onClose: () => void
  isVisible: boolean
}

const Modal = styled(animated.div)`
  backdrop-filter: blur(1rem);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(9, 3, 3, 0.55);
`

const ModalLayout = styled.div`
  width: 100%;
  height: 100%;
  padding: 10.75rem 3.75rem 0 3.75rem;
  @media (max-width: 750px) {
    padding: 10.75rem 0.75rem 0 0.75rem;
  }
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
`

const ModalContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`

const CategoryLabel = styled.span`
  font-size: 8vh;
  margin-bottom: 1.25rem;

  @media (max-width: 750px) {
    font-size: 6vh;
  }

  line-height: 1;
  cursor: pointer;
  transition: color 300ms ease;

  &:hover {
    color: #507ae9
  }
`

const CategoryModal: React.VFC<ICategory> = ({onSet, onClose, isVisible}) => {
  const {categories} = useContext(DataContext);

  const transitions = useTransition(isVisible, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    config: {...config.default, duration: 100}
  });

  return transitions((styles, item) => item && (
    <Modal onClick={onClose} style={styles}>
      <ModalLayout>
        <ModalContent>
          {categories.map((c) => (
            <CategoryLabel
              key={c.slug}
              onClick={() => onSet(c.slug)}
            >#&nbsp;{c.slug}
            </CategoryLabel>
          ))}
        </ModalContent>
      </ModalLayout>
    </Modal>));
}

export default CategoryModal;
