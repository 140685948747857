import React from 'react';
import { graphql } from 'gatsby'
import Layout from "@components/layout";
import App from '@components/app';
import DataContextWrapper from "@components/dataContext";
import SEO from "@components/seo";
import _filter from 'lodash/filter';
import _map from 'lodash/map';

const HomePage = ({data}) => {
  const advices: IAdvice[] = _filter(data.appsync.listAdvice.items, a => !a._deleted);
  const categories: ICategory[] = _filter(data.appsync.listCategories.items, c => !c._deleted);
  const backgrounds: IImage[] = _map(data.allFile.edges, (e) => e.node);

  return <Layout>
    <SEO siteMeta={data.site.siteMetadata}/>
    <DataContextWrapper advices={advices} categories={categories} backgrounds={backgrounds}>
      <App/>
    </DataContextWrapper>
  </Layout>;
}

export const query = graphql`
    query HomePageQuery {
        site {
            siteMetadata {
                title
                description
                shortName
                siteUrl
                image
                siteLanguage
                siteLocale
                authorName
                favicon
                backgroundColor
                themeColor
                twitterUsername
                googleTag
            }
        }
        allFile(filter: {relativeDirectory: {in: ["background", "mobileBackground"]}}) {
            edges {
                node {
                    name
                    relativeDirectory
                    childImageSharp{
                        gatsbyImageData(
                            placeholder: BLURRED,
                            quality: 90
                        )
                    }
                }
            }
        }
        appsync {
            listAdvice(limit: 10000) {
                items {
                    name
                    background
                    categories
                    slug
                    _deleted
                }
            }
            listCategories(limit: 10000) {
                items {
                    slug
                    _deleted
                }
            }
        }
    }
`

export default HomePage
