export function isPlainObject(o: any): boolean {
  return typeof o === 'object' && o !== null && o.constructor === Object;
}

export function isPlainArray(o: any): boolean {
  return typeof o === 'object' && o !== null && o.constructor === Array;
}

export function isEmptyObject(obj: any): boolean {
  return obj === null || obj === undefined || (isPlainObject(obj) && Object.keys(obj).length === 0)
}

export function isEmptyString(s: string | undefined | null): boolean {
  return s === undefined || s === null || s === '';
}

export function concatPath(path: string, subpath: string): string {
  if (!path) {
    return subpath;
  }

  return (path[path.length - 1] === '/') ? path + subpath : path + '/' + subpath;
}

export function isClient(): boolean {
  return typeof window === 'object'
}

export function toSafeJson(input: string): string {
  return input
    .replace(/<(\/?)(script)/gi, '\\u003c$1$2') // $2 here is to preserve case of the tag name
    .replace(/]]>/g, ']]\\u003e')
    .replace(/\u2028/g, '\\u2028')
    .replace(/\u2029/g, '\\u2029')
    .replace(/-->/g, '--\\u003e');
}

export function safeMerge<T>(obj: T, mod?: Partial<T>): T {
  if (isEmptyObject(mod)) {
    return obj;
  }

  const merge = Object.assign({}, obj, mod);

  return shallowEqual(obj, merge) ? obj : merge;
}

export function shallowEqual(objA: any, objB: any): boolean {
  function shallowEqualObjects(objA: any, objB: any): boolean {
    if (!isPlainObject(objB)) {
      return false;
    }

    const aKeys = Object.keys(objA);
    const bKeys = Object.keys(objB);
    const len = aKeys.length;

    if (bKeys.length !== len) {
      return false;
    }

    for (let i = 0; i < len; i++) {
      const key = aKeys[i];

      if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
        return false;
      }
    }

    return true;
  }

  if (objA === objB) {
    return true;
  }

  if (isPlainObject(objA)) {
    return shallowEqualObjects(objA, objB);
  }

  throw new Error(`unknown types to compare! ${typeof objA} vs ${typeof objB}`);
}

export function makeId(length: number): string {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}

export function booleanToString(flag: boolean): string {
  return flag.toString();
}

export function stringToBoolean(input: string | null): boolean {
  return input === 'true';
}
