import React from "react"
import styled from "styled-components";
import {useSpring, animated, config} from "react-spring";

const BurgerLayout = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  cursor: pointer;
`

const BurgerLine = styled(animated.div)`
  background-color: #fff;
  width: 100%;
  height: 3px;
  margin-top: 7px;
`

interface IBurgerProps {
  onClick: () => void;
  isX: boolean
}

const Burger: React.VFC<IBurgerProps> = ({onClick, isX}) => {
  const styles1 = useSpring(
    {
      from: {transform: 'translate3d(0px, 0px, 0px) rotateZ(0deg)'},
      to: {transform: isX ? 'translate3d(0px, 10px, 0px) rotateZ(45deg) ' :
          'translate3d(0px, 0px, 0px) rotateZ(0deg)'},
      config: { ...config.default, duration: 100 }
    });

  const styles2 = useSpring(
    {
      from: {transform: 'rotateZ(0deg)'},
      to: {transform: isX ? ' rotateZ(45deg) ' :
          'rotateZ(0deg)'},
      config: { ...config.default, duration: 100 }
    });

  const styles3 = useSpring(
    {
      from: {transform: 'translate3d(0px, 0px, 0px) rotateZ(0deg)'},
      to: {transform: isX ? 'translate3d(0px, -10px, 0px) rotateZ(-45deg) ' :
          'translate3d(0px, 0px, 0px) rotateZ(0deg)'},
      config: { ...config.default, duration: 100 }
    });

  return (<BurgerLayout onClick={onClick}>
    <BurgerLine style={styles1} />
    <BurgerLine style={styles2} />
    <BurgerLine style={styles3} />
  </BurgerLayout>)
}

export default Burger;
