import React, {useState} from "react";
import styled from "styled-components";
import {IGatsbyImageData} from "gatsby-plugin-image";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

interface ISolid {
 isSolid: boolean;
}

const StyledImage = styled.img<ISolid>`
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  border: 0;
  will-change: opacity;
  transition: opacity;
  transition-duration: 0.5s;

  opacity: ${({isSolid}) => isSolid ? 1 : 0};
`

interface IImageProp {
  alt: string;
  image: IGatsbyImageData;
}

const SpikeImage: React.VFC<IImageProp> = ({image, alt}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (<Wrapper>
    {!!image.placeholder && <picture>
      {image.placeholder.sources?.map((source, index) =>
        <source media={source.media} key={index} srcSet={source.srcSet}/>
      )}
      <StyledImage
        aria-hidden="true"
        decoding="sync"
        isSolid={!isImageLoaded}
        src={image.placeholder.fallback}
        alt={alt}/>
    </picture>}
    {!!image.images && <picture>
      {image.images.sources?.map((source, index) =>
        <source key={index}
                media={source.media}
                sizes={source.sizes}
                type={source.type}
                srcSet={source.srcSet}/>
      )}
      <StyledImage
        width={image.width} height={image.height}
        sizes={image.images.fallback?.sizes}
        decoding="async"
        isSolid={isImageLoaded}
        onLoad={() => setIsImageLoaded(true)}
        loading="lazy"
        src={image.images.fallback?.src}
        srcSet={image.images.fallback?.srcSet} alt={alt}/>
    </picture>
    }
  </Wrapper>)
};

export default SpikeImage;
