import React from "react"
import styled from "styled-components";
import CategoryTag from "@components/categoryTag";
import Circle from "@components/circle";
import _map from 'lodash/map';

const Layout = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`

const Aligner = styled.div`
  padding: 6.75rem 3.75rem;

  @media (max-width: 750px) {
    padding: 4.25rem 0.75rem 4.25rem 0.75rem;
  }
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const AdviceContent = styled.div`
  margin: auto;
  text-align: center;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 2rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;

  @media (max-width: 996px) {
    grid-template-columns: auto;
  }
`

const Header = styled.h1`
  font-size: 14vh;

  @media (max-width: 750px) {
    font-size: 8vh;
  }
  
  margin-bottom: 1.25rem;
  line-height: 1;
  cursor: pointer;
  user-select: none;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
  justify-content: center;
`

interface IAdviceProps {
  advice: IAdvice
  onForward: () => void
  setCat: (category: NullableString) => void
  currentCategory: NullableString
}

const Advice: React.VFC<IAdviceProps> = ({advice, onForward, setCat, currentCategory}) => {
  return (
    <Layout>
      <Aligner>
        <AdviceContent>
          <Circle/>
          <div>
            <Header onClick={onForward}>{advice.name}</Header>
            <CategoryWrapper>
              {_map(advice.categories,
                (category) =>
                  <CategoryTag
                    key={category}
                    category={category}
                    onClick={setCat}
                    isSelected={category === currentCategory} />)}
            </CategoryWrapper>
          </div>
        </AdviceContent>
      </Aligner>
    </Layout>
  )
}

export default Advice;
