import React, {useContext, useMemo, useState} from 'react';
import Footer from '@components/footer';
import Noize from '@components/noize';
import styled from "styled-components";
import {DataContext} from "@components/dataContext";
import Header from "@components/header";
import CategoryModal from "@components/categoryModal";
import {getCurrentAdvice, getPseudoRandomSlug} from "@components/utils/adviceHelper";
import {isClient, isEmptyString} from "@components/utils/fx";
import {StringParam, useQueryParam} from "use-query-params";
import BackgroundTrans from "@components/backgroundTrans";
import AdviceTrans from "@components/adviceTrans";
import sendEvent from "@components/analytics";

const App = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
`

const getValidCategory = (categorySlug: NullableString, categories: ICategory[]): NullableString => {
  if (categorySlug && !isEmptyString(categorySlug)) {
    const theEdge = categories.find((c) => `${c.slug}` === `${categorySlug}`);
    return theEdge ? categorySlug : undefined;
  }

  return undefined;
}

const getValidAdvice = (adviceSlug: NullableString, advices: IAdvice[]): NullableString => {
  if (adviceSlug && !isEmptyString(adviceSlug)) {
    const theEdge = advices.find((a) => `${a.slug}` === `${adviceSlug}`);
    return theEdge ? adviceSlug : undefined;
  }

  return undefined;
}

export default function home() {
  const {advices, categories} = useContext(DataContext);

  //const [querySlug, setQuerySlug] = useState<NullableString>()
  const [querySlug, setQuerySlug] = useQueryParam("advice", StringParam);

  const currentSlug = useMemo(() => {
    return getValidAdvice(querySlug, advices);
  }, [querySlug]);

  //const [queryCategory, setQueryCategory] = useState<NullableString>()
  const [queryCategory, setQueryCategory] =  useQueryParam("category", StringParam);

  const currentCategory = useMemo(() => getValidCategory(queryCategory, categories), [queryCategory]);

  const [history, setHistory] = useState<string[]>([]);
  const [prevSlug, setPrevSlug] = useState<NullableString>(undefined);
  const [modalVisible, setModalVisible] = useState(false);

  const getRnd = () => getPseudoRandomSlug(advices, currentSlug, history, currentCategory);

  const adviceList = useMemo(() => !isClient() ? [] : [getCurrentAdvice(advices, currentSlug, getRnd)],
    [currentSlug]);

  const handleSetSlug = (slug: string) => {
    if (currentSlug && !isEmptyString(currentSlug)) {
      setHistory([currentSlug, ...history.slice(0, 6)]);
      setPrevSlug(currentSlug);
    }

    setQuerySlug(slug || undefined);
  }

  const goNextFromAdvice = () => {
    const value = getRnd();
    sendEvent('nextFromAdvice', {advice: value});
    handleSetSlug(value);
  }

  const goNext = () => {
    const value = getRnd();
    sendEvent('nextFromFooter', {advice: value});
    handleSetSlug(value);
  }

  const goPrev = () => {
    if (prevSlug && !isEmptyString(prevSlug)) {
      sendEvent('prevFromFooter', {advice: prevSlug});
      handleSetSlug(prevSlug);
    } else {
      const value = getRnd();
      sendEvent('prevFromFooter', {advice: value});
      handleSetSlug(value);
    }
  }

  const handleCategoryChange = (category: NullableString) => {
    setModalVisible(false);
    setQueryCategory(category || undefined);

    if (!!category) {
      sendEvent('setCategory', {category: category})
    } else {
      sendEvent('cleanCategory');
    }

    handleSetSlug(getPseudoRandomSlug(advices, currentSlug, history, category));
  };

  const handleCategoryModal = () => {
    setModalVisible((v) => {
      if (!v) {
        sendEvent('showCategoryModal');
      }

      return !v;
    });
  }

  const handleCloseModal = () => {
    setModalVisible(false);
  }

  return <App>
    <Header
      isMenuVisible={modalVisible}
      onCategoryModal={handleCategoryModal}
      category={currentCategory}
      onCleanCategory={handleCategoryChange}/>
    <BackgroundTrans adviceList={adviceList}/>
    <Noize/>
    <AdviceTrans
      adviceList={adviceList}
      onForward={goNextFromAdvice}
      setCat={handleCategoryChange}
      currentCategory={currentCategory}/>
    <CategoryModal onSet={handleCategoryChange} onClose={handleCloseModal} isVisible={modalVisible}/>
    <Footer onForward={goNext} onBack={goPrev}/>
  </App>;
}
