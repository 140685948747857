import React from "react";
import {useTransition, config, animated} from "react-spring";
import Advice from "@components/advice";

interface IAdviceTrans {
  adviceList: IAdvice[];
  onForward: () => void;
  currentCategory: NullableString,
  setCat: (category: NullableString) => void
}

const AdviceTrans: React.VFC<IAdviceTrans> = ({adviceList, onForward, currentCategory, setCat}) => {
  const transitions = useTransition(adviceList, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    delay: 300,
    config: config.default,
  })

  return <div>
    {transitions((styles, currentAdvice) => (
      <animated.div style={styles} data-key={currentAdvice.slug}>
        <Advice
          advice={currentAdvice}
          onForward={onForward}
          currentCategory={currentCategory}
          setCat={setCat}/>
      </animated.div>
    ))}
  </div>
}

export default AdviceTrans;
