import {isClient} from "@components/utils/fx";

export default function sendEvent(action: string, values?: Record<string, string>) {
  if (isClient()) {
    if (typeof window.gtag === 'function') {
      window.gtag('event', action, values);
    } else {
      values ? console.log('event', action, values) : console.log('event', action);
    }
  }
}
