import React from "react"
import styled from "styled-components";
import {isEmptyString} from "@components/utils/fx";
import CategoryTag from "@components/categoryTag";
import Burger from "@components/burger";

const HeaderLayout = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 3.75rem 3.75rem 0 3.75rem;

  @media (max-width: 750px) {
    padding: 0.75rem 0.75rem 0 0.75rem;
  }
`

const CapsNav = styled.span`
  text-transform: uppercase;
  font-size: 1.125rem;
  line-height: 1em;
`

const HomeNav = styled.a`
  cursor: pointer;
`

const CategoryMenu = styled.div`
`

const Title = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`

const CategoryWrap = styled.div`
  margin-top: 5px;
`

interface IHeaderProps {
  category: string | null | undefined;
  onCategoryModal: () => void;
  isMenuVisible: boolean;
  onCleanCategory: (category: NullableString) => void;
}

const Header: React.VFC<IHeaderProps> = ({onCategoryModal, onCleanCategory, category, isMenuVisible}) => {
  return (
    <HeaderLayout>
      <Title>
        <HomeNav href="/">
          <CapsNav>f**king great</CapsNav>
        </HomeNav>

        {(category && !isEmptyString(category)) &&
        (<CategoryWrap>
          <CategoryTag category={category} onClick={() => onCleanCategory(undefined)}/>
        </CategoryWrap>)
        }
      </Title>
      <CategoryMenu>
        <Burger onClick={onCategoryModal} isX={isMenuVisible} />
      </CategoryMenu>
    </HeaderLayout>
  )
}

export default Header;
