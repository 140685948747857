import {isEmptyString} from "@components/utils/fx";
import _includes from 'lodash/includes';

export const getPseudoRandomSlug = (advices: IAdvice[], currentSlug: NullableString,
                                    history: string[], catSlug: NullableString): string => {
  let limit = 10;
  const localHistory = [currentSlug, ...history];

  let collection = isEmptyString(catSlug) ? advices :
    advices.filter((a: IAdvice) => _includes(a.categories, catSlug));

  if (collection.length === 0) {
    collection = advices;
  }

  while (limit > 0) {
    const index = Math.floor(Math.random() * collection.length);
    const candidate = collection[index].slug;
    if (!localHistory.includes(candidate)) {
      return candidate;
    }

    limit--;
  }

  const index = Math.floor(Math.random() * collection.length);
  return advices[index].slug;
}

export const getCurrentAdvice = (advices: IAdvice[], querySlug: NullableString, getFallbackSlug: () => string):
  IAdvice => {
  let result: IAdvice;

  if (!isEmptyString(querySlug)) {
    result = advices.find((item) => `${item.slug}` === `${querySlug}`);
  }

  if (!result) {
    const fallbackSlug = getFallbackSlug();
    result = advices.find((item) => `${item.slug}` === `${fallbackSlug}`);
  }

  return result;
}
